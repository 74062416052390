@import 'styles/layers.scss';

@layer uikit {
  .panel-tooltip {
    :global {
      background: var(--black);
      border-radius: 5px;
      padding: 5px 12px;
    }
  }
}
